.section-title {
    text-align: center;
    margin-top: 26px !important;
    margin-bottom: 26px !important;
}

.shadow-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px 0px;
    border-radius: 16px !important;
}

.shadow-card:hover, .carousell-card:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 8px -4px, rgba(0, 0, 0, 0.14) 0px 12px 17px 2px, rgba(0, 0, 0, 0.12) 0px 5px 22px 4px;
    transform: scale(1.05) !important;
    transition: transform .2s;
}

.disable-link-style-white {
    color: #ffffff !important;
    text-decoration: none !important;
    cursor: pointer !important;
    width: fit-content;
  }


/* .carousell-card:hover{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 8px -4px, rgba(0, 0, 0, 0.14) 0px 12px 17px 2px, rgba(0, 0, 0, 0.12) 0px 5px 22px 4px;
    transform: scale(1.05) !important;
    transition: transform .2s;
} */