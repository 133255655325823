@font-face {
  font-family: "Helvetica";
  src: url('./assets/fonts/Helvetica.ttf');
}

* {
  font-family: Helvetica !important;
}

body {
  background-color: #eeeeee !important;
  margin: 0;
  padding: 0;
}

/* ========= HEADING ======== */
h1 {
  font-size: 40px !important;
  font-weight: bold !important;
  margin: 0px !important;
}

h2 {
  font-size: 32px !important;
  font-weight: bold !important;
  margin: 0px !important;

}

h3 {
  font-size: 28px !important;
  font-weight: bold !important;
  margin: 0px !important;

}

h4 {
  font-size: 24px !important;
  font-weight: bold !important;
  margin: 0px !important;

}

h5 {
  font-size: 20px !important;
  font-weight: bold !important;
  margin: 0px !important;

}

h6 {
  font-size: 16px !important;
  font-weight: bold !important;
  margin: 0px !important;

}

.small-text {
  font-size: 14px !important;
}

.super-small-text {
  font-size: 12px !important;
}

/* ========= SCROLLBAR ======== */

/* Custom Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 24px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1F305C;
  border-radius: 24px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1F305C;
}

/* ========= INPUT NUMBER ======== */

/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/* ==================== */
/* GLOBAL CSS COMPONENT */
/* ==================== */

/* ========= COLOR ======== */

.color-primary {
  color: #1F305C !important;
}

/* ========= COLOR ======== */

/* ========= LINK ======== */

.disable-link-style {
  color: #000000 !important;
  text-decoration: none !important;
  cursor: pointer !important;
}

/* ========= BUTTON ======== */

.button-outlined-primary {
  border: 1px solid #1F305C !important;
  color: #1F305C !important;
  font-weight: bold !important;
}

/* .button-outlined-primary:hover {
  background-color: #1F305C !important;
  color: #ffffff !important;
  border: 1px solid #1F305C !important;
  font-weight: bold !important;
} */

.button-outlined-danger {
  border: 1px solid #D32F2F !important;
  color: #D32F2F !important;
  font-weight: bold !important;
}

/* .button-outlined-tertiary {
  border: 1px solid #D32F2F !important;
  color: #D32F2F !important;
  font-weight: bold !important;
} */

/* .button-outlined-danger:hover {
  background-color: #D32F2F !important;
  color: #ffffff !important;
  border: 1px solid #D32F2F !important;
  font-weight: bold !important;
} */

/* ========= BUTTON ======== */

/* ========= CARD ======== */

.dash-card {
  /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23333' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='30' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 16px; */
  height: 100%;
  width: 100%;
  padding: 16px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;
}

.gap-10 {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 0px;
}

.gap-16 {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 0px;
  /* width: 100%; */
}

.gap-24 {
  display: flex;
  flex-wrap: wrap;
  gap: 24px 0px;
  /* width: 100%; */
}

.centerXY {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* ========= CARD ======== */


/* =================== */
/* OVERWRITE MUI STYLE */
/* =================== */

.css-3dah0e-MuiModal-root-MuiDialog-root {
  z-index: 10000 !important;
}


/* =================== */
/* OVERWRITE SWIPER JS STYLE */
/* =================== */

.swiper-wrapper {
  padding-top: 25px;
  padding-bottom: 40px;
}


/* =================== */
/* OVERWRITE VIDEO REACT JS STYLE */
/* =================== */

.video-react .video-react-big-play-button.video-react-big-play-button-center {
  display: none;
}

.video-react-video {
  background-color: #ffffff;

  height: 100%;
  /* background-color: rgba(0, 0, 0, 0); */
}


.video-react-controls-enabled,
.video-react-paused,
.video-react-user-active,
.video-react-workinghover,
.video-react {
  height: 100%;

}